import React from 'react'
import Link from 'gatsby-link'
import { Layout } from '../layouts';

const ImprintPage = (props) => (
  <Layout location={props.location}>
  <div>
    <h2 className="content-h2">Angaben gemäß Teledienstgesetz:</h2>
    
    <div className="imprint__info">
    <div className="row">
      <div className="imprint__col">Name, Adresse:</div>
      <div className="imprint__col">
        Dr.med. Wolf-Bernd Esswein<br />
        Steinhäuserstr. 2<br />
        76135 Karlsruhe<br />
        Tel.: 0721/84646<br />
        Fax:  0721/848097<br />
        E-Mail: <a href="mailto:praxis@dr-esswein.de" className="d-link">praxis@dr-esswein.de</a>
      </div>
    </div>
    <div className="row">
      <div className="imprint__col">Berufsbezeichnung:</div>
      <div className="imprint__col">Arzt mit Approbation in der Bundesrepublik Deutschland</div>
    </div>
    <div className="row">
      <div className="imprint__col">Aufsichtsbehörde:</div>
      <div className="imprint__col">Kassenärztliche Vereinigung Nordbaden (<a href="https://www.kvnb.de" className="d-link" target="_blank">www.kvnb.de</a>)</div>
    </div>
    <div className="row">
      <div className="imprint__col">Ärztekammer:</div>
      <div className="imprint__col">
        Landesärztekammer Baden-Württemberg
      (<a href="https://www.aerztekammer-bw.de" className="d-link" target="_blank">www.aerztekammer-bw.de</a>) Die aktuell gültige Berufsordnung finden Sie dort über die Suche-Funktion mit den Suchbegriffen "Sonderdruck" und "Berufsordnung".
      </div>
    </div>  	
  </div>  	

  <h2 className="content-h2">Haftungsausschluss (Disclaimer)</h2>
  <p>
  Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links, für dessen Inhalt ausschließlich deren Betreiber verantwortlich sind.
  </p>

<br />
<br />
<br />

  <p>
    Design: BmK-Medienkompetenz	 <a href="www.bmk-medien.de" className="d-link" target="_blank">www.bmk-medien.de</a><br />
    Fotos: Shari-Fotodesign  	<a href="https://shari.fotograf.de/" className="d-link" target="_blank">www.shari.fotograf.de</a>
  </p>
  </div>
  </Layout>
)

export default ImprintPage
